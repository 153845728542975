import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`PaymentsDS is a service that lets developers integrate their businesses/products with `}<a parentName="p" {...{
        "href": "https://developer.mpesa.vm.co.mz/"
      }}>{`M-Pesa`}</a>{` API to facilitate transactions.`}</p>
    <h2 {...{
      "id": "quickstart"
    }}>{`Quickstart`}</h2>
    <p>{`These quickstart guides will teach you how to integrate PaymentsDS to your service.`}</p>
    <p>{`Choose your programming language below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs-java-usage"
        }}>{`Java`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs-javascript-usage"
        }}>{`JavaScript`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs-php-usage"
        }}>{`PHP`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs-python-usage"
        }}>{`Python`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs-ruby-usage"
        }}>{`Ruby`}</a></li>
    </ul>
    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <p>{`PaymentsDS can be used to:`}</p>
    <ul>
      <li parentName="ul">{`Receive money from a mobile account to a business account`}</li>
      <li parentName="ul">{`Send money from a business account to a mobile account`}</li>
      <li parentName="ul">{`Send money from a business account to a another business account`}</li>
      <li parentName="ul">{`Revert a transaction`}</li>
      <li parentName="ul">{`Query the status of a transaction`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      